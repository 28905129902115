import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/rui-cordeiro.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/cruz.jpg" // Tell webpack this JS file uses this image

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Qualidade da Água<br></br> - Peça fundamental</span> <br></br>
              na Produção Suína
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal12} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Dr. Pedro Jose Hernandez de la Cruz
                      </div>
                    
                    <div className="titulo inter-light">
                      Veterinário especialista em suínos - Vall Companys
                      </div>
                      </div>
                    
                  </div>
        
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                       22 de Fevereiro de 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                     Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      350€ +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/qualidade-agua-suinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/YPg7CsdTjkvXdm9K7" target="_blank" >

                
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-matadouro-bovinos">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Masterclass
          </div>
          <div className="text inter-regular">
          Esta formação visa dotar aos técnicos de campo todas as ferramentas necessárias para resolver problemas de água, saber interpretar análises, solucionar problemas, fazer tramento de água e desenhar o posicionamento de tubagens corretamente. Para além disto iremos rever as necessidades dos suinos em termos hidricos, como otimizar o consumo e fazer medicações corretamente.            </div>
      </div>
      <div className="wrapper-logos">
        {/* <div className="col1">
        <img className="imagem" src={logo1} alt="" />

        </div> */}
        {/* <div className="col2">
        <img className="imagem" src={logo2} alt="" />

        </div> */}
      </div>

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
     
      </div>
      <div className="wrapper-program">

      <Accordion defaultActiveKey="0">
   <Accordion.Item eventKey="0">
      <Accordion.Header>
         + Dia 1 - 22/02/2023
      </Accordion.Header>
      <Accordion.Body>
      <div class="modulo">
                                <p><span className="date">08:00 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Introdução à importância da água em suinos, origem e qualidade (microbiologica e fisico-quimica)</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:00</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Recomendações, tratamentos de água (higienização e controlo de pH)</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Amostragem, analítica e interpretação.
Medicação na água: métodos e interações.</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:00 - 16:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">A importância do biofilme e limpeza da canalização
</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">16:30 - 18:00</span> | <span className="mod">Módulo 5</span> - <span class="txt">Casos prático: Resolução de problemas 
Casos prático: Otimização do consumo de água
Caso prático: Modelo de instalação de água numa exploração nova
</span></p>
                                </div>
      </Accordion.Body>
   </Accordion.Item>

</Accordion>




      {/* <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Dia 1 - 29/03/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Apresentação de protocolos do bem estar animal</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Medidas comportamentais</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Medidas sanitárias e ordem das medidas na exploração</span></p>
                                </div>
  
                                </Card.Body>

                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                            Dia 2 - 30/03/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                            <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Visita à fazenda de iscas</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span class="txt">Almoço</span></p>
                                </div>
          
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Medidas sanitárias e ordem das medidas na exploração</span></p>
                                </div>
  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                            Dia 3 - 31/03/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                            <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">09:00 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Repetibilidade no protocolo WQ</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span class="txt">Almoço</span></p>
                                </div>
          
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Repetibilidade WQ. Conclusões e dúvidas</span></p>
                                </div>
  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                            Dia 4 - 01/04/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="3">
                            <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Exame teórico e prático</span></p>
                                </div> 
                
          
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Entrega de certificados e encerramento do curso</span></p>
                                </div>
  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> */}
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/qualidade-agua-suinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/YPg7CsdTjkvXdm9K7" target="_blank" >

                
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal12} alt="" />

              </div>
              <div className="nome">
              Dr. Pedro Jose Hernandez de la Cruz
              </div>
              <div className="titulo">
              Veterinário especialista em suínos - Vall Comapanys
              </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Veterinário responsável pelas engordas de suínos da zona de aragón ( grupo vall companys)</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Responsável veterinario em explorações de reprodutoras da zona da catalunã (grupo vall companys)</p>
                  
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Licenciado en veterinária por la universidad de zaragoza</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Master en sanidad y producción porcina realizado conjuntamente, en las univesidades de lleida, zaragoza y barcelona</p>

                 
                  </div>
              </div>
            </div>
        </div>
        </div>

      
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
